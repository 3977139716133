<template>
  <v-card
    :style="isCreatingUser ? styling.maxEditFieldWidth : ''"
    elevation="0"
    style="min-height: 100%"
    tile
  >
    <v-toolbar
      v-if="!isCreatingUser"
      class="lightGrey"
      elevation="2"
      ref="top"
      style="min-height: 50px"
    >
      Edit
    </v-toolbar>

    <div
      :style="$vuetify.breakpoint.xs ? '' : { paddingRight: '20% !important' }"
      class="px-6 mt-6"
    >
      <v-row
        v-if="!isCreatingUser"
        :class="$vuetify.breakpoint.xs ? '' : 'mb-6'"
        dense
      >
        <v-col :cols="titleColNum" :class="rowHeader"></v-col>
        <v-col align="center">
          <ProfilePicture :userInformation="userInformation" />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          :align="$vuetify.breakpoint.xs ? 'left' : 'right'"
          :class="rowHeader"
          :cols="titleColNum"
          class="font-weight-bold mt-4"
        >
          Details
        </v-col>
        <v-col class="pr-3">
          <div class="d-flex flex-row pb-3">
            <v-text-field
              v-model.trim="userData.firstName"
              :error-messages="errors['firstName']"
              :rules="rules.firstName"
              class="mr-2"
              hide-details="auto"
              maxlength="75"
              outlined
              :disabled="isLoewenUser"
            >
              <template v-slot:label>
                First Name<span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-text-field
              v-model.trim="userData.lastName"
              :error-messages="errors['lastName']"
              :rules="rules.lastName"
              hide-details="auto"
              maxlength="75"
              outlined
              :disabled="isLoewenUser"
            >
              <template v-slot:label>
                Last Name<span class="red--text">*</span>
              </template>
            </v-text-field>
          </div>
          <v-text-field
            v-if="isWebAdmin || isInternalAdmin"
            v-model.trim="userData.username"
            :error-messages="errors['username']"
            :rules="rules.username"
            class="pb-3"
            hide-details="auto"
            maxlength="64"
            outlined
            :disabled="isLoewenUser"
          >
            <template v-slot:label>
              Username<span class="red--text">*</span>
            </template>
          </v-text-field>
          <v-text-field
            v-model.trim="userData.title"
            :error-messages="errors['title']"
            :rules="rules.title"
            class="pb-3"
            hide-details="auto"
            label="Title"
            maxlength="64"
            outlined
            :disabled="isLoewenUser"
          />
          <v-text-field
            v-model.trim="userData.phone"
            :error-messages="errors['phone']"
            :rules="rules.phone"
            @keydown.space.prevent
            class="pb-3"
            hide-details="auto"
            label="Phone"
            outlined
          />
          <v-text-field
            v-model.trim="userData.email"
            :error-messages="errors['email']"
            :rules="emailValidationRules"
            class="pb-3"
            hide-details="auto"
            label="Email"
            maxlength="75"
            outlined
          >
            <template v-slot:label>
              Email<span v-if="isEmailRequired" class="red--text">*</span>
            </template>
          </v-text-field>

          <DateTextField
            v-show="isWebAdmin || isInternalAdmin"
            :hideTextFieldDetails="false"
            :initValue="isCreatingUser ? '' : this.userData.hireDate"
            :isCalendarDisabled="isCalendarDisabled || isLoewenUser"
            :isTextFieldDisabled="isHireDateDisabled || isLoewenUser"
            :key="dateTextFieldRefreshKey"
            :label="'Hire Date'"
            :rules="[rules.date]"
            @onInput="userData.hireDate = $event"
          />
        </v-col>
      </v-row>

      <v-row
        v-if="
          userData.userCustomFields.length && (isWebAdmin || isInternalAdmin)
        "
        dense
      >
        <v-col
          :align="$vuetify.breakpoint.xs ? 'left' : 'right'"
          :class="rowHeader"
          :cols="titleColNum"
          class="font-weight-bold"
        >
          Custom Fields
        </v-col>
        <v-col class="pr-3">
          <v-text-field
            v-for="customField in userData.userCustomFields"
            v-model.trim="customField.value"
            :key="customField.id"
            :label="customField.name"
            maxLength="500"
            class="pb-3"
            hide-details
            outlined
          />
        </v-col>
      </v-row>

      <v-row
        v-if="isWebAdmin || isInternalAdmin"
        :class="$vuetify.breakpoint.xs ? 'mb-0' : 'mb-4'"
        dense
      >
        <v-col
          :align="$vuetify.breakpoint.xs ? 'left' : 'right'"
          :class="rowHeader"
          :cols="titleColNum"
          class="font-weight-bold"
          ref="permissions"
        >
          Permissions
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.xs ? 'mt-n4' : 'py-0'"
          style="margin-left: -3px"
        >
          <v-checkbox
            v-model="userData.isWebAdministrator"
            :disabled="isWebAdminDisabled"
            :false-value="0"
            :true-value="1"
            @change="updatePermissions()"
            hide-details
            label="Web Admin"
          />
          <v-checkbox
            v-model="userData.isSupervisor"
            :disabled="isSupervisorDisabled"
            :false-value="0"
            :true-value="1"
            @change="updatePermissions()"
            hide-details
            label="Supervisor"
          />
          <v-checkbox
            v-model="userData.isManager"
            :disabled="isManagerDisabled"
            :false-value="0"
            :true-value="1"
            @change="updatePermissions()"
            hide-details
            label="Manager"
          />
          <v-checkbox
            v-model="userData.isSafetyRep"
            :false-value="0"
            :true-value="1"
            hide-details
            label="Safety"
          />
          <v-checkbox
            v-if="isInternalAdmin && userData.companyGroupId != 0"
            v-model="userData.isGroupAdministrator"
            :disabled="isGroupAdminDisabled"
            :false-value="0"
            :true-value="1"
            @change="updatePermissions()"
            hide-details
            label="Group Admin"
          />
        </v-col>
      </v-row>

      <v-row
        v-if="isWebAdmin || isInternalAdmin"
        :class="$vuetify.breakpoint.xs ? '' : 'flex-nowrap'"
        dense
      >
        <v-col
          :align="$vuetify.breakpoint.xs ? 'left' : 'right'"
          :class="rowHeader"
          :cols="titleColNum"
          class="font-weight-bold"
        >
          Supervisors
        </v-col>
        <v-col class="pr-3">
          <v-autocomplete
            v-model="userData.attachedSupervisorIds"
            :class="$vuetify.breakpoint.xs ? '' : 'pb-3'"
            :item-value="autocompleteRules.itemValue"
            :items="userData.allSupervisors"
            :menu-props="{
              'content-class': 'text-truncate',
              'max-width': '360',
            }"
            :ref="supervisorsDropdownRef"
            append-icon=""
            hide-details
            item-text="fullname"
            label="Supervisors"
            multiple
            outlined
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                @click:close="removeGroupFromSupervisorLabelIds(item)"
                @click="select"
                close
              >
                {{ item.fullname | limitNameLengthInChip }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row
        v-if="isWebAdmin || isInternalAdmin"
        :class="$vuetify.breakpoint.xs ? '' : 'flex-nowrap'"
        dense
      >
        <v-col
          :align="$vuetify.breakpoint.xs ? 'left' : 'right'"
          :class="rowHeader"
          :cols="titleColNum"
          class="font-weight-bold"
        >
          Groups
        </v-col>
        <v-col class="pr-3">
          <v-autocomplete
            v-model="userData.attachedLabelIds"
            :class="$vuetify.breakpoint.xs ? '' : 'pb-3'"
            :item-value="autocompleteRules.itemValue"
            :items="userData.allLabels"
            :menu-props="{
              'content-class': 'text-truncate',
              'max-width': '360',
            }"
            :ref="groupsDropdownRef"
            append-icon=""
            hide-details
            item-text="name"
            label="Groups"
            multiple
            outlined
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                @click:close="removeGroupFromAttachedLabelIds(item)"
                @click="select"
                close
              >
                {{ item.name | limitNameLengthInChip }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row v-if="isCreatingUser" dense>
        <v-col
          :align="$vuetify.breakpoint.xs ? 'left' : 'right'"
          :class="rowHeader"
          :cols="titleColNum"
          class="font-weight-bold pr-3"
        >
          Password
        </v-col>
        <v-col>
          <div class="d-flex flex-row">
            <v-text-field
              v-model.trim="password"
              :append-icon="isEyeHidden ? '' : passwordIcon"
              :rules="rules.password"
              :type="isPasswordDisplayed ? 'text' : 'password'"
              @click:append="toggleIsPasswordDisplayed"
              @keyup="detectIfRandomPassword"
              class="pb-3"
              hide-details="auto"
              outlined
            >
              <template v-slot:label>
                Password<span class="red--text">*</span>
              </template>
              <template v-slot:append-outer>
                <v-btn
                  :style="
                    $vuetify.breakpoint.xs ? '' : { position: 'absolute' }
                  "
                  @click="generatePassword"
                  class="mr-3 mt-n1"
                  icon
                  small
                >
                  <v-icon color="msaBlue">mdi-cached</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
          <v-checkbox
            v-if="isSendCredentialsShowing"
            v-model="isCredentialsBeingSentToEmail"
            class="mb-n1"
            color="msaBlue"
            label="Send Credentials"
            style="margin-left: -3px"
          />
        </v-col>
      </v-row>

      <v-row v-if="isApiShowing" dense>
        <v-col
          :align="$vuetify.breakpoint.xs ? 'left' : 'right'"
          :class="rowHeader"
          :cols="titleColNum"
          class="font-weight-bold"
        >
          API
        </v-col>
        <v-col>
          <UserApi @isApiEnabled="handleApiEnabled" />
        </v-col>
      </v-row>
    </div>

    <!-- Margin matches padding for div above -->
    <div>
      <v-alert
        v-for="(error, index) in licenseError"
        :key="index"
        class="mx-6"
        dense
        dismissible
        outlined
        type="error"
      >
        {{ error }}
      </v-alert>
    </div>

    <div>
      <v-alert
        v-if="isNonLicenseError"
        class="mx-6"
        dense
        dismissible
        outlined
        type="error"
      >
        {{ isNonLicenseErrorMessage }}
      </v-alert>
    </div>

    <div class="pa-6" ref="userEditModal">
      <v-divider></v-divider>
      <v-col>
        <div align="right">
          <v-btn
            @click="handleCancel()"
            class="mr-4 px-1"
            color="msaBlue"
            id="scrollHere"
            text
          >
            CANCEL
          </v-btn>
          <v-btn
            :class="styling.saveButton"
            :disabled="!isValid"
            @click="save()"
            class="msaBlue white--text"
            elevation="0"
          >
            SAVE
          </v-btn>
        </div>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon';
import { PasswordGenerator } from '@/plugins/PasswordGenerator.js';
import DateTextField from './DateTextField.vue';
import ProfilePicture from './ProfilePicture.vue';
import UserApi from './UserApi.vue';

export default {
  name: 'UserEditModal',
  components: {
    DateTextField,
    ProfilePicture,
    UserApi,
  },
  filters: {
    limitNameLengthInChip(val) {
      const maxLength = 20;

      if (val.length > maxLength) {
        return val.slice(0, maxLength) + '...';
      }

      return val;
    },
  },
  props: {
    companyIdFromRoute: Number,
    drawerContainer: HTMLElement,
    employeeId: Number,
    isCreatingUser: Boolean,
    isDialogVisible: Boolean,
    isInternalAdmin: Boolean,
    isWebAdmin: Boolean,
    scrollToRef: String,
    userInformation: Object,
  },
  data() {
    return {
      dateTextFieldRefreshKey: 9999,
      tempHireDate: '',
      styling: {
        titleCol: 4,
        titleColXS: 12,
        details: ['my-0'],
        groupRow: ['my-n12'],
        rowHeader: ['mt-4', 'mr-4'],
        disabledAnchor: ['lightGrey--text'],
        maxEditFieldWidth: { maxWidth: '750px !important' },
      },
      noEditUserData: {},
      userData: {
        id: '',
        title: '',
        phone: '',
        email: '',
        lastName: '',
        firstName: '',
        username: '',
        hireDate: '',
        status: 1,
        isSafetyRep: 0,
        isManager: 0,
        isSupervisor: 0,
        isWebAppEnabled: 0,
        isWebAdministrator: 0,
        isGroupAdministrator: 0,
        attachedLabelIds: [],
        allLabels: [],
        attachedSupervisorIds: [],
        allSupervisors: [],
        company: {
          id: 0,
          companyGroupId: 0,
        },
        userCustomFields: [],
      },
      // Blank data loaded into user data for clean slate
      createUserData: {
        status: 1,
        firstName: '',
        lastName: '',
        username: '',
        title: '',
        phone: '',
        email: '',
        hireDate: '',
        isGroupAdministrator: 0,
        isWebAdministrator: 0,
        isSupervisor: 0,
        isManager: 0,
        attachedLabelIds: [],
        attachedSupervisorIds: [],
        sendEmail: false,
      },
      password: '',
      isPasswordDisplayed: true,
      isPasswordRandomlyGenerated: false,
      isCredentialsBeingSentToEmail: false,
      rules: {
        date: (v) => this.$helpers.validateDate(v),
        phone: [(v) => this.validatePhone(v)],
        title: [(v) => this.validateTitle(v)],
        username: [(v) => this.validateUsername(v)],
        lastName: [(v) => this.validateLastName(v)],
        password: [(v) => this.validatePassword(v)],
        firstName: [(v) => this.validateFirstName(v)],
        email: [(v) => this.validateEmail(v, this.isEmailRequired)],
      },
      errors: {
        email: [],
        phone: [],
        title: [],
        username: [],
        lastName: [],
        password: [],
        hireDate: [],
        firstName: [],
        isWebAppEnabled: [],
      },
      showApi: true,
      dateSelector: false,
      isNonLicenseError: false,
      errorMessage: '',
      autocompleteRules: {
        itemText: {
          type: String,
          default: 'name',
        },
        itemValue: 'id',
      },
      groupsDropdownRef: 'groupsDropdown',
      supervisorsDropdownRef: 'supervisorsDropdown',
    };
  },
  computed: {
    isLoewenUser() {
      //special case for Loewen users.
      if (this.userData.remoteEmployeeNumber) {
        return true;
      }
      return false;
    },
    isCalendarDisabled() {
      return this.$helpers.validateDate(this.userData.hireDate) !== true;
    },
    isHireDateDisabled() {
      return !this.userData.status && !this.isCreatingUser;
    },
    //COPIED FROM CredentialsSection.vue - Oct. 2023
    isSendCredentialsShowing() {
      if (this.password && this.userData.email) {
        return true;
      }

      return false;
    },
    passwordIcon() {
      const result = this.isPasswordDisplayed ? 'mdi-eye' : 'mdi-eye-off';
      return result;
    },
    isEyeHidden() {
      if (this.password.length == 0) {
        return true;
      }

      return false;
    },
    isNonLicenseErrorMessage() {
      return `Could not save - ${this.errorMessage}`;
    },
    titleColNum() {
      return this.$vuetify.breakpoint.xs
        ? this.styling.titleColXS
        : this.styling.titleCol;
    },
    licenseError() {
      return this.errors.isWebAppEnabled;
    },
    isEmailRequired() {
      if (this.userData.isWebAdministrator) {
        return true;
      }

      if (this.userData.isGroupAdministrator) {
        return true;
      }

      return false;
    },
    isValid() {
      let condition;

      if (this.isCreatingUser) {
        condition =
          this.validatePassword(this.password, true) === true &&
          this.validateEmail(this.userData.email) === true &&
          this.validatePhone(this.userData.phone) === true &&
          this.$helpers.validateDate(this.userData.hireDate) === true &&
          this.validateUsername(this.userData.username) === true &&
          this.validateLastName(this.userData.lastName) === true &&
          this.validateFirstName(this.userData.firstName) === true;
      } else {
        condition =
          this.validateEmail(this.userData.email) === true &&
          this.validatePhone(this.userData.phone) === true &&
          this.validateUsername(this.userData.username) === true &&
          this.validateLastName(this.userData.lastName) === true &&
          this.$helpers.validateDate(this.userData.hireDate) === true &&
          this.validateFirstName(this.userData.firstName) === true &&
          this.isChanges;
      }

      return condition;
    },
    isApiShowing() {
      // showApi is only a value emitted from UserApi and not a complete check

      return (
        this.$store.getters.user.type !=
          this.$constants.USER_TYPES.NORMAL_USER &&
        this.showApi &&
        !this.isCreatingUser
      );
    },
    isRevokingSupervisor() {
      // Return true if supervisor permission is being removed from this user and if user supervising others

      return (
        this.noEditUserData.isSupervising &&
        this.noEditUserData.isSupervisor != this.userData.isSupervisor &&
        (this.isWebAdmin || this.isInternalAdmin)
      );
    },
    isChanges() {
      const result = !this.deepEquals(this.userData, this.noEditUserData);

      if (result) {
        this.$emit('makeStateless');
      } else {
        this.$emit('removeStateless');
      }
      return result;
    },
    isGroupAdminShowing() {
      return (
        this.$store.getters.isInternalAdmin && this.userData.companyGroupId != 0
      );
    },
    isGroupAdminDisabled() {
      if (!this.userData.status && !this.isCreatingUser) {
        return true;
      }
      if (this.$store.getters.user.id == this.userData.id) {
        return true;
      }
      if (!this.userData.isWebAdministrator) {
        return true;
      }
      if (this.$store.getters.isInternalAdmin) {
        return false;
      }
      return true;
    },
    isWebAdminDisabled() {
      /*
      If user is a web admin and trying to edit their own profile,
      prevent them from removing their web admin privileges
      */
      if (this.isWebAdmin && this.$store.getters.user.id == this.userData.id) {
        return true;
      }

      return !this.userData.status && !this.isCreatingUser;
    },
    isSupervisorDisabled() {
      return !this.userData.status && !this.isCreatingUser;
    },
    isManagerDisabled() {
      return !this.userData.isSupervisor;
    },
    rowHeader() {
      if (this.$vuetify.breakpoint.xs) {
        return '';
      }

      return this.styling.rowHeader;
    },
    emailValidationRules() {
      if (this.userData.isWebAdmin) {
        return this.rules.email;
      } else {
        if (this.userData.email == '') {
          return [];
        } else {
          return this.rules.email;
        }
      }
    },
  },
  watch: {
    isDialogVisible: {
      handler(val) {
        if (val) {
          this.initData();

          this.$nextTick(() => {
            const options = {
              offset: 0,
              container: this.drawerContainer,
            };
            this.$vuetify.goTo(this.$refs[this.scrollToRef], options);

            if (
              this.scrollToRef === this.groupsDropdownRef ||
              this.scrollToRef === this.supervisorsDropdownRef
            ) {
              setTimeout(() => {
                this.$refs[this.scrollToRef].focus();
                this.$refs[this.scrollToRef].activateMenu();
              }, 300);
            }
          });
        }
      },
    },
  },
  methods: {
    //COPIED FROM CredentialsSection.vue - Oct. 2023
    generatePassword() {
      this.isCredentialsBeingSentToEmail = true;
      if (!this.isPasswordDisplayed) {
        this.toggleIsPasswordDisplayed();
      }

      if (this.isCredentialsCopied) {
        this.toggleIsCredentialsCopied();
      }

      const numLetters = 6;
      this.password = PasswordGenerator.generate(
        numLetters,
        this.$constants.PASSWORD_VALIDATION_REGEX,
      );

      this.isPasswordRandomlyGenerated = true;
    },
    detectIfRandomPassword(e) {
      /**
       * Without key check, when user would copy credentials from the text field,
       * it would detect that command a.k.a. META (or other non-'normal' keys were detected)
       * and the program would think a user is updating their passsword due to key press.
       * META + C or other key commbinations are not detected as individual key inputs (META then C)
       * And it will only be detected as META
       */

      // Prevents errors from flooding console when Loading page
      if (!e.key) {
        return;
      }

      if (!e.key.length) {
        return;
      }

      if (e.key.length == 1) {
        this.isPasswordRandomlyGenerated = false;
        this.isCredentialsBeingSentToEmail = false;
      }
    },

    scrollToBottom() {
      const modal = document.getElementsByClassName(
        'v-navigation-drawer__content',
      )[0];

      if (modal && this.isScrollToBottom) {
        modal.scrollTop = modal.scrollHeight;
      }

      if (modal && !this.isScrollToBottom) {
        modal.scrollTop = 0;
      }
    },
    clearErrors() {
      for (var key in this.errors) {
        this.errors[key] = [];
      }

      this.isNonLicenseError = false;
    },
    handleCancel() {
      if (this.isCreatingUser) {
        this.isInternalAdmin
          ? this.$router.push({ name: 'IACompanyUsers' })
          : this.$router.push({ name: 'CompanyUsers' });
        return;
      }

      this.dateTextFieldRefreshKey++;
      this.$emit('closeDialog');
    },
    toggleIsPasswordDisplayed() {
      this.isPasswordDisplayed = !this.isPasswordDisplayed;
    },
    initData() {
      this.isNonLicenseError = false;
      for (var key in this.errors) {
        this.errors[key] = [];
      }

      this.userData = JSON.parse(JSON.stringify(this.userInformation));

      if (this.userData.hireDate) {
        this.userData.hireDate = DateTime.fromFormat(
          this.userData.hireDate,
          'MMM dd, yyyy',
        ).toFormat('yyyy-MM-dd');
      }

      if (this.isCreatingUser) {
        Object.assign(this.userData, this.createUserData);

        this.userData.allLabels = this.userInformation.allLabels;
        this.userData.allSupervisors = this.userInformation.allSupervisors;
      } else {
        this.noEditUserData = JSON.parse(JSON.stringify(this.userData));
        delete this.noEditUserData.createdAt; // Needed for computed property isChanges to work so that it matches userData
        this.noEditUserData.sendEmail = false;
        this.noEditUserData.loaderText = 'Loading...';
      }

      this.userData.sendEmail = false;
      this.userData.loaderText = 'Loading...';

      delete this.userData.createdAt; // Prevents createdAt from being updated.
    },
    updatePermissions() {
      if (!this.userData.isSupervisor) {
        this.userData.isManager = 0;
      }

      if (!this.userData.isWebAdministrator && this.isGroupAdminShowing) {
        this.userData.isGroupAdministrator = 0;
      }
    },
    removeGroupFromAttachedLabelIds(item) {
      const indexResult = this.userData.attachedLabelIds.findIndex(
        (labelId) => labelId === item.id,
      );
      if (indexResult !== -1) {
        this.userData.attachedLabelIds.splice(indexResult, 1);
      }
    },
    removeGroupFromSupervisorLabelIds(item) {
      const indexResult = this.userData.attachedSupervisorIds.findIndex(
        (SupervisorId) => SupervisorId === item.id,
      );
      if (indexResult !== -1) {
        this.userData.attachedSupervisorIds.splice(indexResult, 1);
      }
    },
    save() {
      this.$emit('makeStateless');

      // Needed to re-init the value to ensure calendar isn't disabled.
      // Without it, after the first save the date in the format 'LLL dd, yyyy' will be passed down again
      // And initValLoaded in DateTextField will already be set to true
      this.dateTextFieldRefreshKey++;

      if (this.isCreatingUser) {
        this.createUser();
        return;
      }

      if (this.isRevokingSupervisor) {
        this.$root.showMessage(
          'Warning',
          'If the user is added as a supervisor to any employee(s), they will be automatically removed. Are you sure you want to proceed?',
          () => {
            this.updateUserDetailsAndAttachables();
          },
          () => {},
          'Confirm',
          'Cancel',
        );
        return;
      }

      if (this.isInternalAdmin || this.isWebAdmin) {
        this.updateUserDetailsAndAttachables();
        return;
      }

      this.updateUserProfile();
      return;
    },
    updateUserDetailsAndAttachables() {
      const url = 'update-user-details-and-attachables?format=json';
      this.userData.disableAutoError = true;

      // Check if a user is making themselves their own supervisor
      if (this.userData.attachedSupervisorIds.includes(this.userData.id)) {
        this.$root.showMessage(
          'Warning',
          'Cannot make a user their own supervisor. Remove user from supervisor group before saving.',
          () => false,
          null,
          'OK',
        );
        return;
      }

      const getDifferences = (original, updated) => {
        const toAdd = updated.filter((id) => !original.includes(id));
        const toRemove = original.filter((id) => !updated.includes(id));
        return { toAdd, toRemove };
      };

      const labelDifferences = getDifferences(
        this.noEditUserData.attachedLabelIds,
        this.userData.attachedLabelIds,
      );

      const supervisorDifferences = getDifferences(
        this.noEditUserData.attachedSupervisorIds,
        this.userData.attachedSupervisorIds,
      );

      const params = {
        loaderText: 'Saving...',
        employeeId: this.employeeId,
        toModify: {
          labelsToAdd: labelDifferences.toAdd,
          labelsToRemove: labelDifferences.toRemove,
          supervisorsToAdd: supervisorDifferences.toAdd,
          supervisorsToRemove: supervisorDifferences.toRemove,
        },
        companyId: this.companyIdFromRoute,
        userDetails: this.userData,
        snackText: 'Changes Saved.',
        disableAutoError: true,
      };

      this.$axios
        .post(url, params)
        .then(() => {
          if (
            this.$route.name == 'UserProfile' ||
            (this.userData.id == this.$store.getters.user.id &&
              !this.$store.getters.isInternalAdmin)
          ) {
            this.updateStore().then(() => {
              this.$emit('refresh');
            });
          } else {
            this.$emit('refresh');
          }
        })
        .catch((error) => {
          this.$emit('makeStateless');
          this.processErrors(error.response.data);
        });
    },
    createUser() {
      if (this.isInternalAdmin) {
        this.userData.companyId = this.$store.getters.selectedCompany.id;
      }

      this.userData.loaderText = 'Saving...';
      this.userData.disableAutoError = true;
      this.userData.snackText = 'User has been created.';

      this.userData.temporaryPasswordPeek = this.password;
      this.userData.isPasswordRandomlyGenerated =
        this.isPasswordRandomlyGenerated;

      this.userData.sendEmail = this.isCredentialsBeingSentToEmail;
      this.userData.toAdd = {
        labels: this.userData.attachedLabelIds,
        supervisors: this.userData.attachedSupervisorIds,
      };

      this.$axios
        .post('create-user?format=json', this.userData)
        .then((response) => {
          this.$store.commit('updateSelectedUser', this.userData);
          if (this.$store.getters.isInternalAdmin) {
            this.$router.replace({
              name: 'IAUserDetails',
              params: {
                id: response.data,
                companyId: this.$store.getters.selectedCompany.id,
              },
            });
          } else {
            this.$router.replace({
              name: 'UserDetails',
              params: { id: response.data },
            });
          }
        })
        .catch((error) => this.processErrors(error.response.data));
    },
    processErrors(errorData) {
      this.errorMessage = errorData.message;
      const errors = errorData.errors;

      this.clearErrors();

      for (const [key] of Object.entries(errors)) {
        this.errors[key] = [errors[key]];
      }

      if (this.isCreatingUser) {
        this.$nextTick(() => {
          this.$vuetify.goTo(document.body.scrollHeight);
        });
      }

      if (!this.licenseError.length) {
        this.isNonLicenseError = true;
        return;
      }

      // Needed incase there is a license error, and then the user fixes that and then gets a isNonLicenseError
      this.isNonLicenseError = false;
    },
    updateStore() {
      const session = {
        ...this.$store.getters.user,
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        email: this.userData.email,
        isSupervisor: this.userData.isSupervisor,
        isManager: this.userData.isManager,
        isSafetyRep: this.userData.isSafetyRep,
        username: this.userData.username,
      };
      this.$store.commit('updateUser', session);
      return this.updateToken();
    },
    updateToken() {
      const url = 'update-token?format=json';
      return this.$axios
        .post(url, { loaderText: 'Loading...' })
        .then((response) => {
          this.$store.commit('updateJwtToken', response.data);
        });
    },
    updateUserProfile() {
      const url = 'update-user-profile?format=json';

      const userProfileProps = {
        title: this.userData.title,
        phone: this.userData.phone,
        email: this.userData.email,
        lastName: this.userData.lastName,
        firstName: this.userData.firstName,
        loaderText: 'Saving...',
      };

      this.$axios
        .post(url, userProfileProps)
        .then(() => {
          if (
            this.userData.id == this.$store.getters.user.id &&
            !this.$store.getters.isInternalAdmin
          ) {
            this.updateStore().then(() => {
              this.$emit('refresh');
            });
          } else {
            this.$emit('refresh');
          }
        })
        .catch(() => {
          this.$emit('makeStateless');
        });
    },
    validateUsername(username) {
      this.errors.username = [];
      return this.$helpers.validateUsername(username);
    },
    validateEmail(email) {
      this.errors.email = [];

      if (this.isEmailRequired && !this.userData.email) {
        this.errors.email.push('Email is required');
        return false;
      }

      return this.$helpers.validateEmail(email, this.isEmailRequired);
    },
    validatePhone(phone) {
      this.errors.phone = [];
      return this.$helpers.validatePhone(phone);
    },
    validateFirstName(name) {
      this.errors.firstName = [];

      const isUser1LifeAccount =
        this.$store.getters.user.firstName.trim().toLowerCase() ==
        this.$constants.ONE_LIFE_ACCOUNT.FIRST_NAME;

      if (
        !isUser1LifeAccount &&
        !this.isInternalAdmin &&
        name.trim().toLowerCase() == this.$constants.ONE_LIFE_ACCOUNT.FIRST_NAME
      ) {
        return 'Invalid name';
      }

      return this.$helpers.required(name, 'First Name');
    },
    validateLastName(name) {
      this.errors.lastName = [];
      return this.$helpers.required(name, 'Last Name');
    },
    validatePassword(password, isRequired = false) {
      this.errors.password = [];
      return this.$helpers.validatePassword(password, isRequired);
    },
    validateTitle(title) {
      this.errors.password = [];
      return this.$helpers.validateTitle(title);
    },
    deepEquals(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    handleApiEnabled(isEnabled) {
      const routeCheck =
        this.$route.name == 'UserDetails' ||
        this.$route.name == 'IAUserDetails';

      this.showApi = isEnabled && routeCheck;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  background-color: #00000014;

  /* chip text color */
  color: black;

  /* chip X color */
  button {
    color: #00000053;
  }
}
</style>
